import React from "react";
import author from "../images/my-pic.jpg";

const AboutMe = () => {
  return (
    <div id="about" className="container py-5">
      <div className="row">
        <div className="col-lg-6 col-mx-12">
          <div className="photo-wrapper mb-5">
            <img className="profile-img" src={author} alt="author" />
          </div>
        </div>
        <div className="col-lg-6 col-mx-12">
          <h1 className="about-heading">About Me</h1>
          <p></p>
          <div id="paper">
            <div id="pattern">
              <div id="content">
                Hi there! 👋 My name is <span className="initials">a</span>
                bdallah <span className="initials">m</span>ustafa. I am a highly
                motivated and detail-oriented software developer with over 8
                years of hands-on experience, passionately embracing new
                technologies and cutting-edge frameworks across the entire
                software development life cycle. A track record of excellence in
                streamlining development processes and inspiring teams to
                deliver efficient, maintainable solutions that seamlessly align
                with business objectives. Proficient in an array of technologies
                and frameworks, including Java, Spring, Docker, AWS, Hibernate,
                REST APIs, Maven, MySQL, MongoDB, JPA, JavaScript, jQuery, and
                Thymeleaf. Enthusiastic about leveraging these skills to drive
                innovation and create transformative solutions for tomorrow's
                challenges.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
