import React from "react";

const Experience = () => {
  return (
    <div id="experience" className="experience">
      <div className="d-flex justify-content-center my-5">
        <h1>Experience</h1>
      </div>
      <div className="container experience-wrapper">
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>Maharishi International University (Mar. 2022 - Present)</h3>
            <h4>Instructor of Computer Science</h4>
            <output>
              • Proactively mentored students, fostering a supportive learning
              environment and leading to improved comprehension of complex
              programming concepts.
            </output>
            <output>
              • Delivered engaging lectures on NoSQL and MongoDB, earning praise
              for clear and insightful teaching.
            </output>
            <output>
              • Implemented a streamlined development environment, resulting in
              increased efficiency for students' project implementation.
            </output>
            <output>
              • Designed a comprehensive database schema, enabling students to
              follow best practices and produce scalable applications.
            </output>
            <output>
              • Recognized for exemplary code reviews and feedback, leading to
              improved code quality and student performance.
            </output>
            <output>
              <i>
                <b>Technologies Used:</b>
                Java, Spring (Boot, Security, Data, AOP, Cloud), Microservices,
                AWS (Route 53, CloudFront, IAM, S3, EC2, RDS, ACM, API Gateway,
                Lambda, SNS, SQS, DynamoDB, Cognito, CloudWatch), Hibernate,
                REST APIs, IntelliJ, MySQL, MongoDB, Postman, FileZilla, Git,
                GitHub, Docker, Node, Express, TypeScript, Angular, Visual
                Studio Code.
              </i>
            </output>
          </div>
        </div>

        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>ArabiaCell (Jan. 2020 - Dec. 2021)</h3>
            <h4>Senior Java Developer</h4>
            <output>
              • Spearheaded the successful development of the Notification
              Center Enterprise System, empowering the company to efficiently
              manage SMS, WhatsApp, and Push notifications.
            </output>
            <output>
              • Conducted in-depth research and delivered presentations on new
              technologies, influencing strategic decision-making for technology
              adoption.
            </output>
            <output>
              • Provided hands-on mentoring to junior colleagues, resulting in a
              40% increase in their coding proficiency and project delivery.
            </output>
            <output>
              • Streamlined version control and deployment processes, reducing
              software release cycles by 60% and ensuring smoother collaboration
              with outsourced developers.
            </output>
            <output>
              <i>
                <b>Technologies Used:</b>
                Java, Spring (Boot, Security, Data, AOP), AWS (S3, EC2, RDS),
                Docker, Hibernate, REST APIs, MySQL, Maven, ActiveMQ, MongoDB,
                Redis Cache, Thymeleaf, Eclipse, IntelliJ, MySQL Workbench,
                Navicat, Trello, XHTML, JPA, Postman, FileZilla, Git, GitLab,
                HTML, CSS, JavaScript, jQuery.
              </i>
            </output>
          </div>
        </div>

        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>ESKADENIA Software (Sep. 2018 - Jan. 2020)</h3>
            <h4>Technical Team Leader</h4>
            <output>
              • Orchestrated the development of module architecture and database
              structure, aligning projects with business objectives and leading
              to improved system performance.
            </output>
            <output>
              • Guided the team through successful project delivery by
              effectively managing work breakdown structures and implementing
              best practices.
            </output>
            <output>
              • Recognized for outstanding solution architecture design and
              comprehensive technical documentation, contributing to CMMI Level
              5 certification.
            </output>
            <output>
              • Instrumental in ensuring seamless technical flow, resulting in
              an 80% reduction in project errors and increased client
              satisfaction.
            </output>
            <output>
              • Nurtured professional growth among team members, leading to
              enhanced skillsets and timely completion of project milestones.
            </output>
            <output>
              <i>
                <b>Technologies Used:</b>
                Java, Oracle Database, MySQL, Eclipse, Toad for Oracle, JSP,
                JSF, EclipseLink, SOAP, JPA, Servlets, Maven, Gradle, JFrog,
                Team Foundation Server (Microsoft TFS), Postman, FileZilla,
                PuTTY, SecureCRT, HTML, CSS, JavaScript, jQuery.
              </i>
            </output>
          </div>
        </div>

        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>ESKADENIA Software (Oct. 2016 - Aug. 2018)</h3>
            <h4>Senior Java Developer</h4>
            <output>
              • Key driver of a successful product refactoring using
              leading-edge technologies, resulting in the acquisition of new
              clients. Recognized and celebrated at the Company's Annual
              Conference for outstanding contributions to innovation and product
              enhancement.
            </output>
            <output>
              • Developed and deployed robust web-based applications, adhering
              to CMMI Level 3 standards and ensuring high-quality software
              products.
            </output>
            <output>
              • Successfully integrated Payment Gateway APIs with the PaymentHub
              System, resulting in seamless payment processing for thousands of
              transactions daily.
            </output>
            <output>
              • Key contributor to the Smart SIM Registration System, enhancing
              data accuracy and significantly reducing registration and
              documentation processing time.
            </output>
            <output>
              • Actively mentored new hires and junior colleagues, fostering a
              collaborative and growth-oriented environment.
            </output>
            <output>
              <i>
                <b>Technologies Used:</b>
                Java, Oracle Database, MySQL, Eclipse, Toad for Oracle, JSP,
                JSF, EclipseLink, SOAP, JPA, Servlets, Maven, Gradle, JFrog,
                Team Foundation Server (Microsoft TFS), Postman, FileZilla,
                PuTTY, SecureCRT, HTML, CSS, JavaScript, jQuery.
              </i>
            </output>
          </div>
        </div>

        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>ESKADENIA Software (Jul. 2012 - Apr. 2015)</h3>
            <h4>Java Developer</h4>
            <output>
              • Created web application to use in conjunction with a mobile application to calculate mobile data consumption based on operator’s policy.
            </output>
            <output>
              • Managed project structure, business development, and database structure for content management system with mobile application viewing.
            </output>
            <output>
              • Managed project structure, business development, and database structure for content management system with mobile application viewing.
            </output>
            <output>
              • Performed backend programming and database manipulation.
            </output>
            <output>
              • Implemented unit tests and application deployment.
            </output>
            <output>
              <i>
                <b>Technologies Used:</b>
                Java, HTML, CSS, JavaScript, jQuery, Oracle Database, SQLite, Eclipse, Toad for Oracle, Microsoft VSS, JSP, JSF, EclipseLink, SOAP, JPA, JDBC, Servlets, Maven.
              </i>
            </output>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
